@import '@angular/material/prebuilt-themes/indigo-pink.css';

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
	unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
	unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
	/* Text Color */
	--primaryColor-: #1D2A33;
	--secondaryColor-: #55586A;
	--tertiaryColor-: #828597;
	--cSilver-: #ccc;
	--labelcolor-: #A1A4B9;
	--linkColor-: #0080FF;
	--GreenCyan-: #e4fbec;
	--position-label-: #5d6e79;


	--basePrimary-: #153a5b;
	--whiteColor-: #FFFFFF;
	--inputcolor-: #E0E1EB;
	--blackColor-: #000000;
	--yellowColor-: #E49C18;
	--lightYellow-: #FFE28C;
	--alertColor-: #FFAC5C;
	--brightGrey-: #3D4052;
	--lightGrey-: #9da6ac;
	--marketColorGreen-: #27C779;
	--marketbgLightGreen-: #E4FBEC;
	--marketColorYellow-: #DAA43A;
	--marketbgLightGreen-: #E4FBEC;
	--marketbgLightRed-: #FFEDEC;
	--paragraphTextcolor--: #3D4053;
	--lightred-: #FFEDEC;
	--lightred2-: #FFF9E8;
	--lightred3-: FFFDF2;
	--lightred4-: #FEF7CE;
	--pinkColor-: #f72160;
	--marketcolorRed-: #E94C43;
	--card-bg-: #ececec;
	--buy-card-: #d7f7c9;
	--sell-card-: #ffe0e0;
	--blue-dark-: #111236;

	/* Background Color */

	--header-bg: #153A5B;
	--body-bg: #f5f7fb;
	--success-bg: #a4f7d0;
	--error-bg: #E94C43;
	--active-color: #0080FF;
	--light-grey: #F0F1FC;
	--light-bg-grey: #F6FBFF;
	--bg2-: #FFF7E9;
	--bg3-: #FFFAF5;
	--label-bg-: #dae5eb;
	--positive-bg-: #e9fffc;
	--BackgroundLight-: #F8F8FF;
	--BackgroundColor-: #F0F1FF;
	--negative-bg-: #ffeeee;
	--black-bg-: #000;
	--green-bg-: #e4fbec;
	--red-bg-: #ffedec;
	--total-bg-: #ffe0de;
	--td--green-: #cbf7e2;
	--sky-bg-: #a2fff1;
	--negative-red-: #ffcfcf;
	--pink-color-: #ff5275;
	--head-color-light-: #87888A;

	/* SUCEES & FAILURE */
	--successColor-: #36C27C;
	--successColor-dark-: #14A493;
	--errorColor-: #E94C43;
	--OrangeBorder-: #FF9E00;
	--cancelledColor-: #545454;
	--PositiveBg-: #F1FFFD;
	--NegaiveBg-: #FFF0F0;

	/*Border Color*/
	--borderColor-: #C4C4C4;
	--borderDefault-: #EEEEEE;
	--SecondaryBorder-: #E0E1EB;
	--borderColor2-: #e3e3e3;


	/*Button color*/
	--ButtonColor-: #153A5B;
	--DisabledColor-: #CFD0D4;
	--ActiveColor-: #263999, 60%;

	/**/
	--shadow-sml-: 0 0 5px 0 rgba(17, 17, 17, 0.1);
	--shadow-spd-: 0 1px 18px 0 rgba(0, 0, 0, 0.2);
	--shadow-spq-: 0 2px 4px 0 rgb(108 148 184 / 21%);
}

.dbs {
	/* Text Color */
	--primaryColor-: #ed2d23;
	--secondaryColor-: #ffb121;
	--tertiaryColor-: #5faaad;
	--primaryText-: #333333;
	--secondarytext-: #999999;
	--tertiary-text-: #5faaad;
	--footnotes-: #666666;
	--icons-: #ed2d23;


	// background-color
	--body-bg: #f7f5f2;
	--basePrimary-: #ed2d23;


	[class^="icon-"],
	[class*=" icon-"] {
		background-color: var(--icons-);
	}

	/* SUCEES & FAILURE */
	--successColor-: #6a9b1e;
	--errorColor-: #ed2d23;
	--marketcolorRed-:#ed2d23;
}


.airtel {
	/* Background Color */
	--body-bg: #F8F8FF;
	--BackgroundLight-: #F8F8FF;
	--BackgroundColor-: #F0F1FF;
	--InsideBack-: #E7E8FF;
	--SeacrchBack-: #EBEDFF;
	--whiteColor-: #FFFFFF;
	/*	--positive-bg-: #F1FFFD;
	--negative-bg-: #FFF0F0;
	--LinkBackColor-: #ECEFFF;*/
	/* Text Color */
	--primaryColor-: #292C31;
	--secondaryColor-: #3E4146;
	--tertiaryColor-: #696B6F;
	--FourthColor-: #A0A2A4;
	--labelcolor-: #999cc2;
	--linkColor-: #027BFC;
	--GreenCyan-: #00C6A8;

	/*Text Color*/

	--inputcolor-: #999cc2;
	--LinkColor-: #027BFC;
	--GreenColor-: #00C6A8;
	--orangelinkcolor-: #FF9F46;
	--blackColor-: #000000;
	--yellowColor-: #E49C18;

	/*Border Color*/
	--borderColor-: #E7E8FF;
	--borderDefault-: #D7E6FC;

	/*Button color*/
	--ButtonColor-: #000000;
	--DisabledColor-: #7B8591;
	--ActiveColor-: #263999, 60%;

	/* SUCEES & FAILURE */
	--successColor-: #14A493;
	--errorColor-: #CA3838;
	--AlertColor-: #E88F3C;

	/**/
	--shadow-sml-: 0 4px 20px 0 rgba(41, 44, 79, 0.1);
	--shadow-spd-: 0 12px 40px 0 rgba(41, 44, 79, 0.1);

	/*input colors*/
	--border-input-: #C3C6E8;
	--input-color-: #999CC2;

	/*heading color*/
	--head-color-light-: #87888A;
	text-transform: lowercase;
	background-color: var(--body-bg) !important;

	a {
		color: var(--LinkColor-);
		text-transform: lowercase;

		&:hover,
		&:focus {
			text-decoration: none;
			color: var(--LinkColor-);
			outline: none;
		}

		&:focus {
			outline: 5px auto -webkit-focus-ring-color;
			outline-offset: -2px;
		}
	}

	.btn--primary {
		background-color: var(--ButtonColor-);
	}

	.__home_wrap {
		padding: 20px !important;
		padding: 15px;
		border-radius: 15px;
		background: var(--BackgroundLight-);
		border: 1px solid var(--whiteColor-);
		-webkit-box-shadow: var(--shadow-spd-);
		-moz-box-shadow: var(--shadow-spd-);
		-ms-box-shadow: var(--shadow-spd-);
		-o-box-shadow: var(--shadow-spd-);
		box-shadow: var(--shadow-spd-);
	}

	.range-list-container li {
		a {
			text-transform: lowercase;
		}
	}

	// Trading Single Page
	.watchlist--modal,
	.modal--open {
		margin: 0;
		min-height: 100%;
		max-width: 550px;
		font-family: Tondo;
		background: var(--BackgroundColor-);

		&-wrapper {
			.modal--details {
				margin: 0;
				background-color: var(--BackgroundLight-);
			}
		}

		.row {
			margin: 0;
		}
	}

	.similar--stock {
		li {
			background-color: var(--BackgroundLight-);
		}
	}

	.buy--sell {
		.header {
			padding: 0;
			height: 48px;
			background: var(--BackgroundColor-);
		}

		&-modal {
			background: var(--BackgroundColor-);

			.buy--sell-order {
				li {
					background-color: var(--BackgroundLight-);
				}
			}

			.buy--sell-content {
				li {
					background-color: var(--BackgroundLight-);

					.modal--input {
						input {
							background-color: transparent !important;
						}

					}
				}
			}

			.watchlist--box {
				.watchlist--validity {
					.flex--input {
						select {
							background-color: var(--BackgroundLight-);
						}
					}
				}
			}
		}
	}

	// Login Page 
	.form__group {
		position: relative;
		display: block !important;
		float: right;
		width: 100%;
		border-radius: 8px;
		background: var(--BackgroundLight-);
		color: var(--PrimaryColor-);
		-webkit-appearance: none;
		height: 38px;
		font-size: 16px;
		font-weight: 500;
		outline: 0;
		border: 1px solid var(--border-input-);
		padding: 8px 15px;

		.mat-icon-button {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}

		.form_container_btn_wrapper {
			display: block;
			margin-top: 31px;
		}

		.forgot_password {
			margin-bottom: 0 !important;
		}
	}

	.form_container_btn_wrapper {
		display: block;
	}

	.mat-form-field {

		.mat-select,
		.mat-form-field-infix .mat-input-element {
			border: 1px solid var(--SecondaryBorder-);
			padding: 10px;
			border-radius: 8px;
			padding-bottom: 10px !important;
		}

		.mat-form-field-underline {
			display: none;
		}

		span.mat-form-field-label-wrapper {
			position: absolute !important;
			top: -3px;
			left: 25px;
			display: inline-block;
			width: auto;
			background-color: var(--whiteColor-);
			height: 15px;
		}

		span.mat-form-field-label-wrapper label {
			width: calc(100% + 19px) !important;
			transform: unset !important;
			top: 7px !important;
			background-color: var(--whiteColor-);
			text-align: center;
		}

		&.currency-input {
			.mat-form-field-prefix {
				top: 48%;
				transform: translateY(-50%);
				left: 6px;
			}

			.mat-form-field-infix {
				.mat-input-element {
					padding-left: 40px;
				}
			}
		}

		.mat-form-field-flex {
			position: relative;
		}
	}

	.mat-tab-header {
		.mat-tab-label-container {
			.mat-tab-labels {
				.mat-tab-label {
					.mat-tab-label-content {
						text-transform: lowercase !important;
						;
					}
				}
			}
		}
	}

	// Mutual Fund Scheme detail Title name 


	.mf--section {
		.mf--port-nonLogin {
			.port--mf {
				li {
					p {
						text-transform: none;
					}
				}
			}
		}



		.buy--lable,
		.mf--ratingm,
		.td--subm,
		.mf--text,
		.mf--years,
		.mf--scheme-info,
		p.mf--text span,
		ul.fund--info,
		.mf--box label,
		ul.list--holding {
			text-transform: capitalize !important;
		}


		.mat-radio-label-content,
		p.mf--text,
		.mat-tab-label-content {
			text-transform: none !important;
		}
	}

	.mf--buy-head,
	.payment--head {
		font-size: 14px !important;
	}

	.mf--buy-head,
	.payment--head,
	mat-card-title {
		text-transform: uppercase;
	}

	.mat-radio-label-content,
	.mf--head,
	.mf--subHead,
	.mf--choose li,
	.mf--choose li span,
	.payment--installment li span,
	.payment--installment li,
	li.info--med,
	.fund--list-label label,
	.fund--list-table td,
	mat-card-subtitle,
	.issue--price p {
		text-transform: none !important;
	}


	.upi--label {
		text-transform: lowercase;
	}

	.btn--buy {
		background-color: #000;
	}


	// Payment Page

	.fund--payment {
		.mat-accordion {
			.upi--input {
				input {
					margin-top: 20px;
					height: auto;
					min-height: auto;
				}
			}
		}
	}

	// Scheme Details Header BG Color

	.main--header {
		background-color: var(--body-bg) !important;

		.header--t--left {
			background: inherit !important;
		}
	}

}

.dinero {
	--primaryColor-: #111236;
	--secondaryColor-: #FFF;
	--tertiaryColor-: #001714;
	--errorColor-: #e34552;
	--successColor-: #36C27C;
	--inputcolor-: #B9C1CA;
	--borderColor-: #DDD;
	--linkColor-: #0095ff;
	--OrangeBorder-: #01988c;
	--basePrimary-: #3C1183;

	--marketbgLightGreen-: #e9fffc;
	--marketbgLightRed-: #fee;

	--marketColorRed-: #FD6464;
	--marketColorGreen-: #00C6A8;
	--marketColorYellow-: #DAA43A;

	--marketbgLightGreen-: #e9fffc;
	--marketbgLightRed-: #fee;

	--basePrimary-: #01988c;
	--ToggleNSE-: #62b5e4;
	--ToggleBSE-: #e34552;
	--password-: #0198ac;

	--basePrimary-: #153a5b;
	/*	--myTertiaryColor-: #f72160;

	--SwitchUnselect-: #f1f1fd;*/
	/*	--SwitchSelect-: #0080ff;*/
	--brightGrey-: #55586A;
	--lightinColor-: #828597;
	--yellowColor-: #FDBF00;
}

.fivenance {
	--primaryColor-: #1D2A33;
	--secondaryColor-: #FFF;
	--tertiaryColor-: #9DA6AC;
	--errorColor-: #e43433;
	--inputcolor-: #B9C1CA;
	--borderColor-: #E0E1EB;
	--OrangeBorder-: #FF9E00;
	--basePrimary-: #00CA9D;
	--linkColor-: #0080FF;

	--marketColorRed-: #E94C43;
	--marketColorGreen-: #27C779;
	--marketColorYellow-: #DAA43A;

	--marketbgColorGreen-: #9CF4C9;
	--marketbgColorRed-: #FFB8B3;

	--marketbgLightGreen-: #E4FBEC;
	--marketbgLightRed-: #FFEDEC;

	/*	--myBaseColor-: #153a5b;
	--myTertiaryColor-: #f72160;*/

	/*	--SwitchUnselect-: #f1f1fd;
	--SwitchSelect-: #0080ff;*/

	--brightGrey-: #55586A;
	/*	--lightinColor-: #828597;*/
	--yellowColor-: #FDBF00;
}

.niyo {
	--primaryColor-: #111236;
	--secondaryColor-: #001714;
	--tertiaryColor-: #001714;
	--errorColor-: #e34552;
	--inputcolor-: #B9C1CA;
	--borderColor-: #DDD;
	--OrangeBorder-: #01988c;
	--basePrimary-: #111236;
	--linkColor-: #0095ff;
	--successColor-: #01988c;

	--marketColorRed-: #FD6464;
	--marketColorGreen-: #00C6A8;
	--marketColorYellow-: #DAA43A;

	--marketbgLightGreen-: #e9fffc;
	--marketbgLightRed-: #fee;

	--marketbgColorGreen-: #e9fffc;
	--marketbgColorRed-: #fee;

	--basePrimary-: #111236;
	--brightGrey-: #55586A;
	--yellowColor-: #FDBF00;
	--labelColor-: #828597;
	--lightinColor-: #828597;
	--PrimaryBtn-: #111236;


	div,
	* {
		font-family: 'Rubik', sans-serif;
	}

	.mat-tab-header {
		.mat-tab-label-container {
			.mat-tab-labels {
				.mat-tab-label {
					.mat-tab-label-content {
						font-weight: 600;
						color: var(--labelColor-);
						text-transform: capitalize;
					}

					&.mat-tab-label-active {
						.mat-tab-label-content {
							color: var(--header-bg);
						}
					}
				}
			}
		}
	}

	// Company details page
	#dvScripInfoModal {
		.watchlist--modal-wrapper {
			.modal--descVal {
				color: var(--primaryColor-);

				td {
					font-weight: 600;

					p {
						color: var(--lightinColor-);
						font-weight: 100;
					}

					span {
						color: var(--successColor-);

						&.negative {
							color: var(--errorColor-);
						}
					}
				}
			}

			.modal--header {
				font-weight: 600;
			}

			.holding--data {
				li {
					flex: 1;
					padding: 7px 0;
					flex: 0 0 33.33%;
					font-size: 11px;
					color: var(--lightinColor-);
					font-weight: 300;
				}
			}

			.stock--btn .btn button {
				border: 1px solid;
				background: transparent;
				padding: 10px 30px;
				border: 1px solid #000;
			}
		}
	}

	.bottom--filter-wrapper {
		.bottom--head {
			ul {
				li {
					.name--val {
						font-weight: 200;
						color: var(--lightinColor-);
					}
				}
			}

			label {
				font-weight: 300;
				font-weight: 300;
				font-size: 14px;
				/* color: #0095ff; */
				text-transform: uppercase;
			}
		}
	}

	.bottom--wrapper {
		.btn {
			button {
				text-transform: capitalize;
				font-weight: 400;
				border-radius: 4px;
			}

			.btn--secondary {
				background: transparent;
				border: 1px solid;
			}
		}
	}

	.bottom-button-wrapper {
		button {
			border-radius: 4px;

			&.btn--sell {
				background: var(--basePrimary-);
				color: var(--whiteColor-);
			}

			&.btn--buy {
				background: var(--OrangeBorder-);
				color: var(--whiteColor-);
			}

		}
	}

	.btn {
		button {

			&.procedd-btn,
			&.btn--primary {
				background-color: var(--basePrimary-);
				color: var(--whiteColor-);
			}

		}
	}

	.buy--sell {
		.btn {
			button {
				&.btn--buy {
					background: var(--basePrimary-);
					color: var(--whiteColor-);
				}
			}
		}
	}

	section {
		.stock-details {
			.stock-content {
				.sentence {
					font-size: 12px;
					line-height: 1.8;
					text-align: center;

					h3 {
						font-weight: 400;
					}
				}
			}
		}
	}

	section {
		.set-alert-button {
			button {
				&.btn {
					background: var(--PrimaryBtn-) !important;
					color: var(--whiteColor-) !important;
					border-radius: 4px !important;
				}
			}
		}
	}

	.stock--sip {
		.stock--sip_white {
			.sip_head {
				font-size: 18px;
				font-weight: 600;
				position: relative;
				margin-bottom: 10px;
			}
		}
	}

	.btn .button {
		border-radius: 4px;
	}


	.toaster--messager {
		span.text-message {
			text-transform: uppercase;
		}
	}

	// buy sell margin info popup

	.bottom--filter {
		p {
			font-weight: 500;
		}
	}
}

.spot {
	--primaryColor-: #1D2A33;
	--secondaryColor-: #FFF;
	--tertiaryColor-: #9DA6AC;
	--errorColor-: rgb(228, 52, 51);
	--inputcolor-: #B9C1CA;
	--borderColor-: #E0E1EB;
	--OrangeBorder-: #FF9E00;
	--basePrimary-: #111d26;
	--linkColor-: #0080FF;

	--marketColorRed-: #E94C43;
	--marketColorGreen-: #27C779;
	--marketColorYellow-: #DAA43A;

	--marketbgColorGreen-: #9CF4C9;


	--marketbgLightGreen-: #E4FBEC;
	--marketbgLightRed-: #FFEDEC;
	--marketbgColorRed-: #FFB8B3;
	--brightGrey-: #55586A;
	--yellowColor-: #FDBF00;
}

.niyox {
	--OrangeBorder-: #01988c;
	--marketColorYellow-: #DAA43A;
	/*	--ToggleBSE-: #e34552;*/
	/*	--myTertiaryColor-: #f72160;*/
	--YellowColor-: #FDBF00;
}


*,
:after,
:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

* {
	font-family: 'Roboto';
}

html,
body {
	font-family: 'Roboto', sans-serif;
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	overflow-x: hidden;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	list-style: none;
}

a,
a:hover,
a:focus {
	color: var(--linkColor-);
	text-decoration: none;
}

.t--center {
	text-align: center;
}

.t--right {
	text-align: right;
}

.t--left {
	text-align: left;
}

.p--fixed {
	position: fixed;
}

.d--block {
	display: block;
}

hr {
	margin: 25px 0;
}

.mT15 {
	margin-top: 15px;
}

.opacity-0 {
	opacity: 0;
}

.link-color {
	color: var(--active-color)
}

.mat-snack-bar-container {
	background: transparent;
}

.toaster--messager {
	position: fixed;
	text-align: left;
	right: 0;
	bottom: -100px;
	left: 0;
	width: 95%;
	margin: 0 auto;
	background: var(--successColor-);
	color: var(--whiteColor-);
	padding: 15px;
	font-weight: 400;
	letter-spacing: 1px;
	transition: all 0.5s ease;
	font-size: 16px;
	z-index: 1500;
	border-radius: 8px;
	padding-left: 20px;

	span.icon {
		width: 21px;
		height: 20px;
		text-align: center;
		line-height: 20px;
		left: 0;
		padding: 0;
		font-size: 15px;
		top: 0;
		border-radius: 21px;
	}

	.icon-check {
		border-radius: 50%;
		background: var(--whiteColor-);
		color: var(--successColor-);
		width: 15px;
		height: 15px;
		padding: 3px;
	}

	&.toaster--open {
		bottom: 50px;
		padding-left: 48px;

	}

	&.negative--toaster {
		font-size: 14px;
		background: var(--errorColor-) !important;
		display: flex;
		align-items: center;
		column-gap: 11px;

		.icon-Close {
			border-radius: 50%;
			background: var(--whiteColor-);
			color: var(--errorColor-);
			width: 15px;
			height: 15px;
			padding: 3px;

		}
	}
}

// .bottom--sheet {
// 	min-height: 90vh !important;
// 	border-top-left-radius: 30px !important;
// 	border-top-right-radius: 30px !important;
// }

// stock sip know more

.bottom--wrapper {
	overflow: hidden;
}

.lv-filter--option {
	width: 165px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0 0 10px 0 rgb(108 148 184 / 30%);
	-moz-box-shadow: 0 0 10px 0 rgba(108, 148, 184, .3);
	-ms-box-shadow: 0 0 10px 0 rgba(108, 148, 184, .3);
	-o-box-shadow: 0 0 10px 0 rgba(108, 148, 184, .3);
	box-shadow: 0 0 10px 0 rgb(108 148 184 / 30%);
	position: absolute;
	right: 10px;
	background: #fff;
	font-size: 12px;
	color: #1d2a33;
	top: 0;
	display: none;
	z-index: 100;
}

//end of custom classes
// MOON ICON

@font-face {
	font-family: 'icomoon';
	src: url('/assets/fonts/icomoon.eot?nhbgvs');
	src: url('/assets/fonts/icomoon.eot?nhbgvs#iefix') format('embedded-opentype'),
		url('/assets/fonts/icomoon.ttf?nhbgvs') format('truetype'),
		url('/assets/fonts/icomoon.woff?nhbgvs') format('woff'),
		url('/assets/fonts/icomoon.svg?nhbgvs#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
	content: "\e975";
}

.icon-mpin-password:before {
	content: "\e974";
}

.icon-stock-sip:before {
	content: "\e974";
}


.icon-insurance:before {
	content: "\e967";
}

.icon-feed-ipo:before {
	content: "\e968";
}

.icon-feed-mf:before {
	content: "\e969";
}

.icon-news:before {
	content: "\e96a";
}

.icon-offers:before {
	content: "\e96b";
}

.icon-order-placed:before {
	content: "\e96c";
}

.icon-order-status:before {
	content: "\e96d";
}

.icon-others:before {
	content: "\e96e";
}

.icon-portfolio:before {
	content: "\e96f";
}

.icon-price-alert:before {
	content: "\e970";
}

.icon-customize:before {
	content: "\e971";
}

.icon-feed:before {
	content: "\e972";
}

.icon-ideas1:before {
	content: "\e973";
}

.icon-time:before {
	content: "\e966";
}

.icon-Price-Alert:before {
	content: "\e964";
}

.icon-Message:before {
	content: "\e963";
}

.icon-Fund-Share:before {
	content: "\e965";
}

.icon-graph:before {
	content: "\e962";
}

.icon-myprofile:before {
	content: "\e95e";
}

.icon-prfemail:before {
	content: "\e95f";
}

.icon-prfmobile:before {
	content: "\e960";
}

.icon-PrfAddress:before {
	content: "\e961";
}

.icon-ideas:before {
	content: "\e95d";
}

.icon-my-report:before {
	content: "\e95c";
}

.icon-bin:before {
	content: "\e9ac";
}

.icon-bin2:before {
	content: "\e9ad";
}

.icon-mf:before {
	content: "\e95b";
}

.icon-subscription:before {
	content: "\e95a";
}

.icon-viewed:before {
	content: "\e959";
}

.icon-positive:before {
	content: "\e958";
}

.icon-negative:before {
	content: "\e957";
}

.icon-locked:before {
	content: "\e956";
}

.icon-reminder:before {
	content: "\e955";
}

.icon-up-dwn:before {
	content: "\e954";
}

.icon-rotate:before {
	content: "\e953";
}


.icon-credit-card:before {
	content: "\e952";
}

.icon-support:before {
	content: "\e950";
}

.icon-bank:before {
	content: "\e951";
}

.icon-mail1:before {
	content: "\e94d";
}

.icon-contact:before {
	content: "\e94e";
}

.icon-address:before {
	content: "\e94f";
}

.icon-cart:before {
	content: "\e94c";
}

.icon-whatsapp:before {
	content: "\e948";
}

.icon-remove-watchlist:before {
	content: "\e949";
}

.icon-disable-watchlist:before {
	content: "\e94a";
}

.icon-add-watchlist:before {
	content: "\e94b";
}

.icon-star:before {
	content: "\e947";
}

.icon-sent-exchange:before {
	content: "\e944";
}

.icon-filter-search:before {
	content: "\e945";
}

.icon-order-filter:before {
	content: "\e946";
}

.icon-square:before {
	content: "\e93d";
}

.icon-increase:before {
	content: "\e93e";
}

.icon-decrease:before {
	content: "\e93f";
}

.icon-exchange:before {
	content: "\e940";
}

.icon-convert:before {
	content: "\e941";
}

.icon-chain:before {
	content: "\e942";
}

.icon-buy:before {
	content: "\e943";
}

.icon-refresh:before {
	content: "\e93c";
}

.icon-Feed:before {
	content: "\e93a";
}

.icon-overflow:before {
	content: "\e93b";
}

.icon-transaction-history:before {
	content: "\e939";
}

.icon-pricing:before {
	content: "\e938";
}

.icon-watchlist:before {
	content: "\e937";
}

.icon-change-password:before {
	content: "\e935";
}

.icon-ipo:before {
	content: "\e936";
}

.icon-mail:before {
	content: "\e933";
}

.icon-download:before {
	content: "\e934";
}

.icon-Menu-user:before {
	content: "\e92e";
}

.icon-call:before {
	content: "\e92f";
}

.icon-email:before {
	content: "\e930";
}

.icon-privacy:before {
	content: "\e931";
}

.icon-terms:before {
	content: "\e932";
}

.icon-cheque:before {
	content: "\e92d";
}

.icon-Incomeproof:before {
	content: "\e92c";
}

.icon-Pan-card:before {
	content: "\e92b";
}

.icon-dashboard:before {
	content: "\e92a";
}

.icon-upload-doc:before {
	content: "\e927";
}

.icon-ipv:before {
	content: "\e928";
}

.icon-esign:before {
	content: "\e929";
}

.icon-task-holding:before {
	content: "\e924";
}

.icon-task-funds:before {
	content: "\e925";
}

.icon-task-order:before {
	content: "\e926";
}

.icon-filter:before {
	content: "\e922";
}

.icon-square-off:before {
	content: "\e921";
}

.icon-plus:before {
	content: "\e902";
}

.icon-minus:before {
	content: "\e903";
}

.icon-search:before {
	content: "\e913";
}

.icon-share:before {
	content: "\e919";
}

.icon-sort:before {
	content: "\e91a";
}

.icon-details:before {
	content: "\e91b";
}

.icon-edit:before {
	content: "\e91c";
}

.icon-funds:before {
	content: "\e91d";
}

.icon-holdings:before {
	content: "\e91e";
}

.icon-info:before {
	content: "\e91f";
}

.icon-orders:before {
	content: "\e920";
}

.icon-Close:before {
	content: "\e912";
}

.icon-Hamburger:before {
	content: "\e900";
}

.icon-Logout:before {
	content: "\e915";
}

.icon-user-check:before {
	content: "\e918";
}

.icon-user:before {
	content: "\e917";
}

.icon-arrow-down-circle:before {
	content: "\e901";
}

.icon-award:before {
	content: "\e905";
}

.icon-bell:before {
	content: "\e906";
}

.icon-calendar:before {
	content: "\e907";
}

.icon-camera:before {
	content: "\e908";
}

.icon-camera-off:before {
	content: "\e909";
}

.icon-check:before {
	content: "\e90a";
}

.icon-check-circle:before {
	content: "\e90b";
}

.icon-arrow-Sdown:before {
	content: "\e90c";
}

.icon-arrow-Sup:before {
	content: "\e90f";
}

.icon-arrow-left:before {
	content: "\e90d";
}

.icon-arrow-right:before {
	content: "\e90e";
}

.icon-arrow-down:before {
	content: "\e904";
}

.icon-arrow-up:before {
	content: "\e923";
}

.icon-eye:before {
	content: "\e910";
}

.icon-eye-off:before {
	content: "\e911";
}

.icon-paperclip:before {
	content: "\e914";
}

.icon-trash:before {
	content: "\e916";
}


// New Footer Icon implemented on 19/04


.icon-new-dashboard:before {
	content: "\e975";
}

.icon-new-feed-mf:before {
	content: "\e976";
}

.icon-new-holdings:before {
	content: "\e977";
}

.icon-new-Menu-user:before {
	content: "\e978";
}

.icon-new-orders:before {
	content: "\e979";
}

.icon-new-watchlist:before {
	content: "\e97a";
}

/*INPUT COMMON */
.form__group {
	position: relative;
	margin-bottom: 25px;

	&_right {
		position: absolute;
		right: 0;
	}

	&_label {
		font-size: 12px;
		color: var(--secondaryColor-);
	}

	&_input {
		border: solid 1px var(--borderColor-);
		padding: 10px;
		width: 100%;
		border-radius: 3px;
	}
}

.form_container_btn_wrapper {
	display: flex;
	margin-bottom: 15px;

	.btn {
		display: inline-block;
		padding: 6px 12px;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 500;
		.t--center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 4px;
		margin-right: 15px;
		width: 100%;
		outline: none;
		letter-spacing: 0.5px;
		text-transform: capitalize;
		position: relative;

		&:last-child {
			margin-right: 0;
		}

		&.btn_primary {
			background: var(--ButtonColor-);
			color: var(--whiteColor-);
		}

		&.mat-button-disabled {
			opacity: 0.5;
		}
	}
}

.hide {
	display: none !important;
}

/* Not Your Account Start */
.not_your_account {
	font-size: 12px;
	color: var(--tertiaryColor-);
	line-height: 16px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

/* Not Your Account End  */

/* Header Start */

._header {
	z-index: 10;
	background: var(--whiteColor-);
	box-shadow: 0 2px 4px rgb(108 148 184 / 38%);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0;

	@media @screen-bigscreen {
		padding: 35px 20px;
		max-height: 70px;

		._header_arrow {
			display: none;
		}
	}

	span {
		@media @screen-bigscreen {
			position: absolute;
			left: 0;
			right: 0;
		}
	}

	&-img {
		width: 150px;

		img {
			width: 100%;
		}

		@media @screen-mobile {
			display: none;
		}
	}

	&_arrow {
		font-size: 26px;
		width: 48px;
		height: 48px;
		text-align: center;
		line-height: 48px;
		color: var(--primaryColor-);
	}

	&_progress {
		display: flex;
		width: 50%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		li {
			position: relative;
			flex: 1;
			text-align: center;

			&:before {
				content: "";
				display: block;
				margin: 0 auto;
				width: 8px;
				height: 8px;
				border: 2px solid #5974f2;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				position: absolute;
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
				border-radius: 50%;
			}

			&:after {
				content: "";
				height: 1px;
				width: 50%;
				position: absolute;
				display: block;
				background: var(--inputcolor-);
				transform: translate(50%, 0);
				top: 30%;
				left: 50%;
			}

			&:last-child:after {
				content: none;
			}

			span {
				position: absolute;
				transform: translate(-50%, 30%);
				top: 50%;
				line-height: 1.5;
				left: 50%;
				color: var(--tertiaryColor-);
				font-size: 9px;
				display: none;
			}

			&._active {
				&:before {
					width: 10px;
					height: 10px;
					background-color: #5974f2;
				}

				span {
					display: block;
				}
			}
		}
	}

	&_offer {
		background-image: linear-gradient(270deg, #f85996 0%, #8a43a7 100%);
		border-radius: 100px;
		font-size: 10px;
		color: var(--whiteColor-);
		padding: 2px 10px;
		text-transform: uppercase;
		margin-right: 15px;
		line-height: 18px;
	}

	.text {
		font-size: 10px;
		color: var(--Secondarycolor-);
		text-align: right;
		font-weight: 500;
		margin-right: 15px;
	}
}

/* Header End */

/* Main form Wrapper Start */
._form_wrapper {
	padding: 0px;
	background-color: var(--whiteColor-);

	._get_ifsc {
		position: absolute;
		right: 0;
	}

	&_ifsc_detail {
		padding: 12px 16px;
		background: #f5faff;
		margin-top: 20px;
		border-top: 4px;

		span {
			font-size: 12px;
			color: var(--primaryColor-);
			letter-spacing: 0;
			line-height: 22px;
		}

		.value {
			margin-left: 8px;
		}
	}

	h2 {
		font-size: 24px;
		color: var(--primaryColor-);
		line-height: 32px;
		margin-bottom: 3px;
	}

	p {
		font-size: 12px;
		color: var(--secondaryColor-);
		letter-spacing: 0;
		margin-bottom: 20px;
	}

	.mat-checkbox {
		display: block;
	}

	// @media @screen-mobile {
	// 	// .row {
	// 	// 	margin: 0;
	// 	// }
	// }
}

/* Main form Wrapper End */

/* Button Start */

.bottom-button-wrapper {
	width: 100%;
	//box-shadow: 0 1px 10px 0 rgba(108, 148, 184, 0.2);
	padding: 16px;
	display: flex;
	justify-content: center;
	column-gap: 10px;

	@media @screen-bigscreen {
		position: relative;
	}

	@media @screen-mobile {
		position: inherit;
		bottom: 0;
		width: 100%;
		left: 0;
		z-index: 10;
	}

	button {
		width: 100%;
		padding: 14px;
		border: 0;
		border-radius: 10px;
		height: 47px;
		font-size: 16px;
		letter-spacing: 0;
		text-align: center;

		@media @screen-small-mobile {
			font-size: 14px;
		}

		&[disabled] {
			background-color: var(--DisabledColor-);
		}

		// @screen-bigscreen {
		// 	max-width: 250px;
		// }
	}

	.transparent-btn {
		background: var(--whiteColor-);
		border: 1px solid var(--basePrimary-);
		border-radius: 10px;
		color: var(--basePrimary-);
		font-weight: 500;
	}
}

/* Button End */

/*Form verified ,modified,loading icon Start*/
.form--group-rightSection {
	position: absolute;
	right: 0;
	top: 15px;
	z-index: 12;

	.input--verified {
		color: var(--successColor-);
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0.5px;
		display: inline-block;

		.icon-check {
			position: relative;
			top: 2px;
			margin-right: 5px;
			font-size: 16px;
		}
	}

	.input--close {
		width: 20px;
		height: 20px;
		text-align: center;
		padding-top: 2px;
		margin-top: -3px;
		display: inline-block;

		.icon-Close {
			background-color: var(--error-bg);
			border-radius: 100%;
			padding: 2px;
			width: 12px;
			height: 12px;
			color: var(--whiteColor-);
			font-size: 12px;
		}
	}
}

.input--loader {
	border: 2px solid var(--yellowColor-);
	border-radius: 50%;
	border-top: 2px solid var(--whiteColor-);
	width: 15px;
	height: 15px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	display: inline-block;

	.orange--border {
		border: 2px solid var(--yellowColor-);
		border-top: 2px solid var(--whiteColor-);
	}
}

/*Form verified ,modified,loading icon End*/


/*Any Doubt Page Start*/
._content_wrapper {
	padding: 0 16px;
	color: var(--primaryColor-);

	h2 {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}

	p {
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		margin-bottom: 15px;
	}

	.more-detail {
		margin: 20px 0;
	}
}

/*Any Doubt Page End*/


/* Gloable Input Field style Start*/

/* Material Label Start */
mat-label {
	display: block;
	margin-bottom: 8px;
	font-size: 12px;
	color: var(--tertiaryColor-);
	letter-spacing: 0;
	font-weight: 500;
}

/* Material Label End */

/* Material Radio Button Start */
.mat-radio-group {
	display: block;
	margin-bottom: 25px;

	.mat-radio-container {
		display: none;
	}

	.mat-radio-label-content {
		border-radius: 15px;
		padding: 5px 25px;
		font-size: 12px;
		margin-right: 10px;
		border: 1px solid var(--tertiaryColor-);
		color: var(--tertiaryColor-);
	}

	/* Checked Radio Button Styling */
	.mat-radio-checked {
		.mat-radio-label-content {
			border-color: var(--active-color);
			color: var(--active-color);
		}
	}
}

/* Material Radio Button End */

/* Material Input Type Text  Start*/
.mat-form-field {
	width: 100%;

	.mat-form-field-flex {
		padding: 0 !important;
		background-color: transparent;
	}

	.mat-form-field-infix {
		display: flex;
		flex-wrap: wrap-reverse;
		width: 100%;
		padding-bottom: 4px;

		.mat-form-field-label-wrapper,
		.mat-form-field-label {
			position: relative;
			line-height: 1;
			overflow: visible;
			padding: 0;

			mat-label {
				display: inline-block;
				margin: 0;
			}

			.mat-placeholder-required {
				display: inline-block;
				margin-left: 3px;
				color: var(--errorColor-);
			}
		}

		.mat-input-element {
			font-size: 18px;
			border: 0;
			padding: 0 0 5px;
			width: 100%;
		}

		.error-field {
			border-bottom-color: var(--errorColor-);
		}

		.error-text {
			position: absolute;
			bottom: -15px;
			color: var(--error-bg);
			font-size: 10px;
		}

		.mat-input-element::placeholder {
			font-size: 18px;
			color: var(--inputcolor-);
			font-weight: 400;
		}
	}

	&.mat-form-field-appearance-fill {
		.mat-form-field-subscript-wrapper {
			padding: 0;
		}
	}

}

/* Material Input Type text end*/

/* MATERIAL SELECT START*/

.select_option {
	.mat-form-field-type-mat-native-select {
		.mat-form-field-underline {
			display: none;
		}

		.mat-form-field-infix {
			&:after {
				content: "\e90c";
				font-family: "icomoon";
				font-size: 21px;
				border: 0;
				width: auto;
			}

			.mat-input-element {
				border-bottom: 1px solid var(--borderColor2-) !important;
				padding-top: 10px;
			}

			.mat-form-field-label-wrapper {
				top: -15px;
			}
		}
	}
}

/* MATERIAL SELECT END*/

/* MATERIAL SELECT START*/

.select_option {
	.mat-form-field-type-mat-native-select {
		.mat-form-field-underline {
			display: none;
		}

		.mat-form-field-infix {
			&:after {
				content: "\e90c";
				font-family: "icomoon";
				font-size: 21px;
				border: 0;
				width: auto;
			}

			.mat-input-element {
				border-bottom: 1px solid var(--borderColor2-) !important;
				padding-top: 10px;
			}

			.mat-form-field-label-wrapper {
				top: -15px;
			}
		}
	}
}

/* MATERIAL SELECT END*/

/* Material Expansion  Start*/

.mat-accordion {
	.mat-expansion-indicator {
		position: absolute;
		right: 3px;
		top: 5px;
		line-height: 4px;

		&:after {
			color: var(--active-color);
			border-color: var(--active-color);
		}
	}

	.mat-content {
		width: auto;
		display: inline-block;

		.mat-expansion-panel-header-title {
			color: var(--active-color);
			margin: 0 20px 25px 0;
		}
	}

	.mat-expansion-panel {
		box-shadow: none;

		.mat-expansion-panel-header {
			display: inline-block;
			width: auto;
			padding: 0;
			position: relative;
			height: auto;
		}
	}

	.mat-expansion-panel-spacing {
		.mat-expansion-panel-header-title {
			margin: 0;
			margin-right: 20px;
		}

		.mat-expansion-panel-content {
			padding: 25px 0;

			.mat-expansion-panel-body {
				padding: 0;
			}
		}
	}
}

/* Material Expansion  End*/


.blank-div {
	height: 20px;
	background: var(--body-bg);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: transparent;
}

/* Material Checkbox Start*/
.mat-checkbox {
	margin-bottom: 25px;

	.mat-checkbox-layout {
		white-space: normal;

		.mat-checkbox-input {
			height: 20px;
			width: 20px;
			position: static !important;
			appearance: checkbox;
			-webkit-appearance-: checkbox !important;

		}

		.mat-checkbox-frame {
			border: 0;
		}

		.mat-checkbox-checkmark-path {
			stroke-dashoffset: 22.910259;
		}

		.mat-checkbox-label {
			margin-left: 10px;
		}
	}

	span.mat-checkbox-inner-container {
		margin-right: 15px;
		height: auto;
	}

	&.mat-checkbox-checked.mat-accent {
		.mat-checkbox-background {
			background-color: transparent;
		}
	}
}

/* Material Checkbox End*/

/* Material Prefix Currency Start*/
.currency-input {
	.mat-form-field-flex {
		margin-bottom: 0;

		.mat-input-element {
			padding-left: 15px;
			padding-bottom: 0;
		}
	}

	.mat-form-field-prefix {
		position: absolute;
		bottom: 24px;
		left: 0;
		font-size: 16px;
	}
}

/* Material Prefix Currency End*/

/* Material Toggle Button Start*/
.switch--button {
	margin-bottom: 25px;

	.mat-button-toggle-group {
		background-color: var(--light-grey);
		border-radius: 100px;
		font-size: 9px;
		color: var(--blackColor-);
		text-align: center;
	}

	.mat-button-toggle-checked {
		color: var(--whiteColor-);
		background-color: var(--active-color);
	}

	.mat-button-toggle-button {
		.mat-button-toggle-label-content {
			padding: 0 12px;
			line-height: 20px;
		}
	}
}

/* Material Toggle Button End*/
.ui-slider {
	width: 93%;
	height: 5px;
	background: var(--inputcolor-);
	display: block;
	position: relative;
	box-shadow: none;
	overflow: initial;
	margin: 15px auto 30px;

	.ui-slider-range-min {
		background: var(--linkColor-);
		height: 3px;
	}

	.ui-slider-handle {
		background: var(--linkColor-);
		display: inline-block;
		width: 10px;
		height: 10px;
		top: -4px;
		position: absolute;
		outline: 0;
		margin-left: -5px;
		border-radius: 10px;
	}

	label {
		position: absolute;
		margin-left: -15px;
		text-align: center;
		margin-top: 5px;
		font-size: 11px;
		color: #A1A4B9;
		width: 30px;
		letter-spacing: -1px;
	}
}

/* Material Slider Start*/
.mat-slider.mat-slider-horizontal {
	display: block;

	.mat-slider-track-background {
		background: var(--inputcolor-);
	}

	.mat-slider-track-fill,
	.mat-slider-thumb {
		background: var(--active-color);
	}

	.mat-slider-thumb-container {
		.mat-slider-thumb {
			transform: none !important;
		}
	}
}

/* Material Slider Start*/
.next-btn {
	background: var(--basePrimary-);
	border-radius: 10px;
	color: var(--whiteColor-);
}

// Verified Badge
.verified {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 0 auto;
	color: var(--whiteColor-);

	.content-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 4;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		.content {
			.check {
				display: flex;
				align-items: center;
				justify-content: center;

				&:after {
					content: '';
					display: block;
					margin-top: -0.5em;
					width: 22px;
					height: 40px;
					border: solid var(--whiteColor-);
					border-width: 0 9px 9px 0;
					transform: rotate(45deg);
				}
			}
		}
	}

	.badge-wrapper {
		position: relative;

		.badge-layer {
			border-radius: 21%;
			width: 56px;
			height: 56px;
			position: relative;
			transform: rotate(45deg);
			z-index: 3;
			background-color: #27C779;

			&:before {
				border-radius: 21%;
				content: "";
				position: absolute;
				height: 56px;
				width: 56px;
				background-color: inherit;
				transform: rotate(135deg);
			}
		}

		.grow {
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(1.1);
			z-index: 2;
		}

		.shadow {
			box-shadow: 0.12em 0.12em 0.5em rgb(0 0 0 / 23%), -0.12em 0.12em 0.5em rgb(0 0 0 / 23%);
			background-color: transparent;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			&:before {
				box-shadow: 0.12em 0 0.5em rgb(0 0 0 / 23%), 0 -0.12em 0.5em rgb(0 0 0 / 23%);
			}
		}

	}
}

// IPO Material Tab
.ipo {

	// Tab Header Styling
	// IPO Card Styling
	&--card {
		padding: 0 15px;

		li {
			width: 100%;
			border-radius: 5px;
			box-shadow: 0 0px 5px rgb(0 0 0 / 30%);
			margin-bottom: 15px;
			background-color: var(--whiteColor-);
			position: relative;

			.badge {
				background: var(--successColor-);
				padding: 3px 19px 3px 5px;
				display: inline-block;
				text-transform: uppercase;
				border-radius: 5px 0 0 0;
				position: relative;
				font-size: 9px;
				top: -3px;
				color: var(--whiteColor-);

				+.ipo--list-table {
					padding-top: 0;
				}

				&:before {
					content: "";
					padding: 6px;
					position: absolute;
					right: -8px;
					display: block;
					background: var(--whiteColor-);
					transform: rotate(45deg);
				}
			}

			.ipo--list-table {
				padding: 9px 15px;
				display: block;

				tbody {
					display: inherit;

					tr {
						display: flex;
						justify-content: space-between;

						td {
							color: var(--primaryColor-);
							font-weight: 700;

							p {
								margin: 5px 0px 10px;
							}

							&.issue--price {
								letter-spacing: 0.5px;

								&:nth-child(1) {
									width: 50%; //lot size width issue
								}

								&:nth-child(2) {
									width: 50%;
								}

								p {
									margin: 0;
									font-size: 10px;
									color: var(--tertiaryColor-);
									font-weight: 200;
								}

								.ipo--details {
									font-size: 12px;
									padding-top: 10px;
									margin-right: 10px;
								}

								.btn {
									justify-content: flex-end;
									display: flex;
									column-gap: 10px;

									.border-btn {
										background: transparent;
										padding: 0;
										color: var(--linkColor-);

										span {
											line-height: 36px;
											margin-right: 5px;
										}
									}
								}
							}
						}
					}

					.issue--date {
						font-size: 11px;
						color: var(--tertiaryColor-);
						line-height: 15px;

						span {
							font-size: 11px;
							color: var(--primaryColor-);
							line-height: 15px;
						}
					}
				}
			}
		}
	}

	// Content Part
	.mat-tab-body-wrapper {
		.mat-tab-body-content {
			padding: 15px 0;
		}
	}

	// IPO Bids
	&--bids {
		.mat-expansion-panel {
			&.mat-expanded {
				.mat-expansion-panel-header {
					padding-bottom: 0;
					position: static;
				}
			}

			&-header {
				padding: 0;
				padding-bottom: 20px;
				height: 100%;
			}

			&-content {
				.mat-expansion-panel-body {
					padding: 0 15px 20px;
				}
			}

			.ipo--list {
				&-table {
					width: 100%;
					font-size: 15px;
					font-weight: 500;
					padding-bottom: 0px !important;

					.issue--ack {
						width: 50%;
						font-size: 12px;
						color: var(--primaryColor-);
						padding: 10px 0;

						label {
							display: block;
							color: var(--tertiaryColor-);
							font-size: 10px;
							font-weight: 400;
						}
					}
				}
			}
		}

		.past--bids {
			table {
				border-spacing: 0;
				border-collapse: collapse;
			}

			.list--open {
				border-top: solid 1px var(--inputcolor-);

				.ipo--list-table {
					width: 100%;
					font-size: 15px;
					font-weight: 500;
					padding: 0px !important;

					.issue--ack {
						sup {
							top: 3px;
							position: relative;
							line-height: 0;
						}

						label {
							display: block;
							color: var(--tertiaryColor-);
							font-size: 10px;
							font-weight: 400;
						}
					}
				}

				.ipo--list-box {
					font-size: 11px;
					width: 100%;
					font-weight: 400;

					tr:first-child {
						td {
							color: var(--tertiaryColor-);
							font-size: 10px;
						}
					}

					td {
						padding: 10px;
						font-size: 11px;
						letter-spacing: 0;
						font-size: 11px;
						color: var(--primaryColor-);
						border: 1px solid var(--inputcolor-);
						font-weight: 500;
						text-align: center;

						&:nth-child(3) {
							text-align: right !important;
						}

						sup {
							vertical-align: initial;
						}
					}
				}
			}
		}

		.mat-expansion-indicator {
			position: absolute;
			bottom: 0;
			display: block;
			width: 100%;
			left: 0;
			text-align: center;
			// background-color: #f9fafb;
			height: 15px;

			&:after {
				border-width: 0 1px 1px 0;
				padding: 2px;
				top: -5px;
				position: relative;
			}
		}
	}
}

.mat-tab-header {
	box-shadow: 0 7px 8px rgb(108 148 184 / 20%);
	padding: 0 20px;
	background-color: var(--whiteColor-);

	.mat-tab-header-pagination {
		display: none !important;
	}

	.mat-tab-label-container {
		.mat-tab-labels {
			justify-content: space-between;

			.mat-tab-label {
				padding: 0;

				.mat-tab-label-content {
					color: var(--tertiaryColor-);
					text-transform: uppercase;
					white-space: nowrap;
					min-width: 80px;
					display: block;
				}

				&-active {
					opacity: 1;

					.mat-tab-label-content {
						color: var(--header-bg);
					}

					&:after {
						border-radius: 5px 5px 0px 0px;
						background-color: var(--header-bg);
						height: 3px;
						width: 100%;
						position: absolute;
						bottom: 0;
						display: block;
						content: "";
					}
				}
			}
		}
	}
}


// Ipo Dropdown
.main-section {
	.ipo-apply-header {
		background-color: var(--whiteColor-);
		padding: 0 10px;
		box-shadow: 0 2px 8px 0 rgb(108 148 184 / 21%);
		margin-bottom: 10px;

		.ipo-dropdown {
			display: flex;
			justify-content: space-between;
			align-items: center;
			column-gap: 20px;
			padding: 10px 15px;
			border: 1px solid var(--inputcolor-);
			border-radius: 5px;
			margin-bottom: 10px;

			li {
				width: 50%;
				font-size: 12px;
				line-height: 1.5;
				color: var(--primaryColor-);

				&:first-child {
					border-right: 1px solid var(--borderColor2-);
					padding-right: 15px;
					font-weight: 700;
				}

				.mat-form-field-wrapper {
					padding: 0;

					.mat-form-field-flex {
						padding: unset !important;
						margin: 0;

						.mat-form-field-infix {
							display: block;
							padding: 0;
							margin: 0;
							border: 0;

							.mat-form-field-label-wrapper {
								display: none;
							}

							&:after {
								font-size: 15px;
							}

							.mat-select-arrow-wrapper {
								transform: none;
							}

							.mat-input-element {
								font-size: 12px;
								color: var(--secondaryColor-);
								line-height: 1.5;
								padding: 0;
								letter-spacing: 0;
								border: 0 !important;

								&:focus {
									border-color: transparent;
								}
							}
						}
					}
				}

				.mat-form-field-underline {
					display: none;
				}
			}
		}

		.ipo-discount {
			text-align: center;
			margin-bottom: 15px;

			div {
				display: inline-block;
				padding: 6px 14px;
				background: #fffaf6;
				border-radius: 18px;
				min-width: 126px;

				span {
					font-size: 11px;
					color: var(--yellowColor-);
					margin: 0 9px;
					letter-spacing: 0;
				}
			}
		}

		.ipo-info {
			display: grid;
			grid-template-columns: 50% 50%;
			font-size: 11px;

			li {
				padding: 7px;
				color: var(--primaryColor-);
				letter-spacing: 0.6px;
				font-weight: 700;

				label {
					float: right;
					color: var(--primaryColor-);
					font-weight: 500;
				}
			}
		}
	}

	.ipo-head {
		font-size: 15px;
		color: var(--primaryColor-);
		letter-spacing: 0;
		font-weight: 500;
		margin-bottom: 10px;
		text-transform: uppercase;
		position: relative;

		&.block-fund-upi {
			margin: 0;
			line-height: 20px;
			font-weight: 600;

			a {
				font-size: 12px;
				font-weight: 100;
				letter-spacing: 0.5px;
				margin-left: 5px;
				text-transform: capitalize;
				position: absolute;
				right: 0;
				top: 3px;
			}
		}

		&-remove {
			font-size: 11px;
			letter-spacing: 0.5px;
			margin-left: 5px;
			text-transform: capitalize;
			position: absolute;
			right: 0;
			top: 3px;
		}
	}

	.ipo-bid {
		background: var(--whiteColor-);
		border-radius: 20px;
		padding: 15px 10px;
		margin-bottom: 10px;

		.multiple-bid {
			margin-bottom: 30px;
		}

		.ipo-head {
			position: relative;
			margin: 0;
			font-weight: 700;

			.ipo-head-remove {
				font-size: 11px;
				font-weight: 100;
				letter-spacing: 0.5px;
				margin-left: 5px;
				text-transform: capitalize;
				position: absolute;
				right: 0;
				top: 3px;
			}
		}

		.ipo-row {
			display: flex;
			column-gap: 20px;

			.flex-input {
				display: flex;
				position: relative;

				.mat-form-field-flex {
					margin: 0;
				}

				.incre-decre {
					position: absolute;
					right: 0;
					bottom: 10px;

					.flex-incre {
						font-size: 16px;
						text-align: center;
						color: #828597;
						border: 1px solid #828597;
						width: 20px;
						height: 20px;
						line-height: 1;
						margin: 5px 5px 0;
						display: inline-block;
					}
				}

				.currency-input {
					.mat-form-field-prefix {
						bottom: 11px;
					}
				}
			}

			.ipo-lots,
			.ipo-cutoff {
				font-size: 12px;
				margin-top: 5px;
				color: var(--primaryColor-);
				opacity: 0.8;

				.mat-checkbox {
					margin-bottom: 0;

					.mat-checkbox-inner-container {
						margin: 0;
						top: 3px;
					}

					.mat-checkbox-layout {
						.mat-checkbox-input {
							width: 15px;
							height: 15px;
							margin: 0;
						}
					}
				}
			}

			span.ipo-error {
				color: var(--errorColor-);
				font-size: 10px;
				display: block;
				bottom: 0;
				font-weight: 500;
			}

			.mat-form-field-wrapper {
				padding-bottom: 0;
			}
		}

		.ipo-total {
			display: flex;
			justify-content: space-between;
			padding: 10px;
			background: var(--tertiaryColor-);
			font-size: 16px;
			background-color: var(--body-bg);
			border-color: var(--inputcolor-);
			border-width: 1px 0 1px 0px;
			border-style: solid;
			margin-top: 15px;
			font-weight: 400;
			letter-spacing: 0.7px;
			margin-bottom: 20px;

			li {
				font-size: 15px;
				color: var(--primaryColor-);
				line-height: 27px;
				font-weight: 600;

				&:last-child {
					font-size: 20px;
					color: var(--primaryColor-);
					letter-spacing: 0;
					text-align: right;
				}
			}
		}

		.add-multiple-bids {
			text-align: center;
		}
	}

	.payment-id-wrapper {
		background-color: var(--whiteColor-);
		padding: 20px 15px;
		border-radius: 20px;
		margin-bottom: 10px;

		.upi-id {
			.ipo-text {
				font-size: 11px;
				// color: var(--linkColor-);
				letter-spacing: 0;
				line-height: 15px;
			}
		}
	}

	.ipo-not {
		text-align: center;
		padding: 100px 0;

		img {
			width: 75px;
			margin-bottom: 15px;
		}

		div {
			font-size: 14px;
			color: #3D4052;
		}
	}
}

.mat-select-panel-wrap mat-label {
	padding: 0 16px;
	height: 36px;
	line-height: 36px;
	margin: 0;
}

.fixd-input {
	.mat-form-field-label-wrapper {
		padding: 0;
		top: 0;
	}

	.mat-form-field-underline {
		bottom: 0 !important;
	}

	.mat-form-field-infix {
		min-height: 70px;
	}
}

//market value for success and failure
.market--val {
	color: var(--successColor-);
	position: relative;
	font-weight: 500;

	&.val--negative {
		color: var(--errorColor-);
	}
}

//market value for success and failure

//button fixed css
.button-wrapper {
	display: flex;
	column-gap: 15px;
	justify-content: center;
	margin-top: 15px;

	.btn {
		&--border {
			background: var(--whiteColor-);
			border: 1px solid var(--basePrimary-);
			border-radius: 10px;
			color: var(--basePrimary-);
			font-weight: 500;
		}
	}
}


.btn {
	&--primary {
		background: var(--basePrimary-);
		color: var(--whiteColor-);
	}

	&--relative {
		padding: 0 20px;
	}

	.button {
		display: inline-block;
		padding: 10px 12px;
		margin-bottom: 0;
		font-size: 12px;
		font-weight: 700;
		text-align: center !important;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 5px;
		margin-right: 15px;
		width: 100%;
		outline: 0;
		letter-spacing: .5px;
		text-transform: uppercase;
		position: relative;
		min-height: 36px;
	}

	&--fixed {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 15px;
		background: var(--BackgroundColor-);
		-webkit-box-shadow: var(--shadow-sml-);
		-moz-box-shadow: var(--shadow-sml-);
		-ms-box-shadow: var(--shadow-sml-);
		-o-box-shadow: var(--shadow-sml-);
		box-shadow: var(--shadow-sml-);
		background: var(--whiteColor-);
		display: flex;
	}

	&--buy {
		background: #27C779;
		color: var(--whiteColor-);
	}

	&--sell {
		background: #E94C43;
		color: var(--whiteColor-);
	}

	&--small {
		text-transform: capitalize;
		font-size: 12px;
		font-weight: 300;
		width: auto;
		padding: 8px;
	}

	.button:last-child {
		margin-right: 0;
	}

}

//col values
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	position: relative;
	min-height: 1px;
	display: inline-block;
}

.col-xs-6 {
	width: 50%;
}

.no_all_padding>div {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

//hr line css
hr {
	margin-top: 18px;
	margin-bottom: 18px;
	border: 0;
	border-top: 1px solid var(--SecondaryBorder-);
}

.calendar {
	.mat-form-field-flex {
		position: relative;

		.mat-form-field-suffix {
			position: absolute;
			right: 0;
			bottom: 0px;

			.mat-icon-button {
				background-color: transparent;

				svg {
					color: var(--linkColor-);
				}

			}
		}
	}
}

.toast_message {
	background-color: var(--active-color);
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.20);
	border-radius: 5px;
	padding: 20px;
	color: var(--whiteColor-);
	position: relative;
	display: flex;
	align-items: center;
	column-gap: 10px;
	margin: 25px 10px;

	.text {
		span {
			font-size: 14px;
			color: inherit;
			letter-spacing: 0;
			font-weight: 300;
			line-height: 21px;

			strong {
				display: block;
				font-size: 18px;
				color: inherit;
				line-height: 26px;
				letter-spacing: 0;
			}
		}
	}
}

.cdk-global-overlay-wrapper {
	.mat-snack-bar-handset {
		.mat-snack-bar-container {
			margin: 0;
			padding: 0;
			background-color: var(--whiteColor-);
		}
	}
}

.user-info {

	.personal-details,
	.dp-details,
	.nominee-details {
		.mat-expansion-panel-body {
			padding: 0;
			margin: 0 24px 0px;
			padding-bottom: 10px;
			border-bottom: 1px solid var(--SecondaryBorder-);
		}
	}

	.mat-expansion-panel-body {
		margin: 20px 15px 0px;
		padding: 0;
		padding-bottom: 20px;
		border-bottom: 1px solid var(--SecondaryBorder-);
	}
}

// Payment Page
.pricing {
	&--radio {
		display: flex;
		justify-content: center;

		.mat-radio-group {
			width: 50%;
			margin: 0;

			.mat-radio-button {
				width: 100%;
				margin-bottom: 15px;
			}

			.mat-radio-label-content {
				width: 100%;
				height: 77px;
				display: flex;
				padding: 10px 15px;
				position: relative;
				border-color: var(--inputcolor-);
				border-radius: 10px;

				label {
					font-size: 15px;
					color: var(--primaryColor-);
					font-weight: 500;
					position: static;
					text-align: left;

					&::before {
						content: '';
						border-bottom: solid 2px var(--whiteColor-);
						border-left: solid 2px var(--whiteColor-);
						display: block;
						position: absolute;
						right: 14px;
						top: 16px;
						width: 12px;
						height: 6px;
						transform: rotate(-45deg);
						z-index: 1;
					}

					&::after {
						content: '';
						border: 2px solid var(--SecondaryBorder-);
						display: block;
						position: absolute;
						right: 10px;
						top: 10px;
						width: 20px;
						height: 20px;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						-ms-border-radius: 50%;
						-o-border-radius: 50%;
						border-radius: 50%;
					}

					.offer-price {
						font-weight: 700;
						line-height: 25px;
						font-size: 18px;
					}

					.disabled {
						font-size: 14px;
						text-decoration: line-through;
						color: var(--tertiaryColor-);
					}
				}
			}

			.mat-radio-checked {
				.mat-radio-label-content {
					border-width: 2px;
					border-color: var(--ActiveColor-);

					label {
						&:after {
							border: 2px solid var(--active-color);
							background: var(--active-color);
						}
					}
				}
			}
		}

	}

	&--checkbox {
		.mat-checkbox {
			margin-bottom: 0;

			.mat-checkbox-label {
				margin-top: 5px;
				margin-left: 0px;
			}

		}


	}

	&--coupon {
		border-radius: 5px;
		box-shadow: 0 2px 4px 0 rgba(108, 148, 184, 0.21), 0 -2px 4px 0 rgba(108, 148, 184, 0.12);
		margin: 20px 0;

		.coupon--info {
			display: flex;
			padding: 10px 0;
			font-size: 13px;
			font-weight: 500;

			li {
				font-weight: 600;
				font-size: 14px;
				color: var(--primaryColor-);
				letter-spacing: 0;

				&:last-child {
					font-size: 30px;
					font-weight: 600;
					flex: 0 30%;
					max-width: 30%;

					.disabled {
						font-size: 14px;
						font-weight: 600;
						text-decoration: line-through;
						color: var(--tertiaryColor-);
					}
				}

				&:first-child {
					max-width: 70%;
					flex: 0 70%;
				}

				a {
					font-size: 10px;
					color: var(--active-color);
					letter-spacing: 0;
					font-weight: 400;
					text-align: right;
				}
			}
		}

		hr {
			margin: 10px 0;
		}

		.coupon--action {
			font-weight: 500;
			padding-bottom: 0px;
			font-size: 12px;
			color: var(--tertiaryColor-);
			line-height: 16px;

			span {
				color: var(--active-color);
			}
		}
	}

	&--accordion {
		.mat-accordion {
			.mat-expansion-panel {
				border-bottom: 1px solid var(--SecondaryBorder-);

				&.mat-expansion-panel-spacing {
					margin: 0;

					.mat-expansion-panel-content {
						padding-top: 0;
						padding-left: 30px;
					}
				}

				.mat-expansion-panel-header {
					width: 100%;
					display: block;

					.mat-content {
						display: block;
						width: 100%;

						.mat-expansion-panel-header-title {
							padding: 20px 0;
							margin: 0;
							font-size: 14px;
							color: var(--primaryColor-);
							letter-spacing: 0;
							font-weight: 600;

							img {
								width: 20px;
								height: 20px;
								border: 1px solid var(--SecondaryBorder-);
								padding: 2px;
								border-radius: 3px;
								margin-right: 10px;
							}

							.available-bal {
								font-size: 14px;
								color: var(--primaryColor-);
								line-height: 20px;
								font-weight: 300;
							}
						}
					}
				}

				&.mat-expanded {
					.mat-expansion-panel-content {
						padding: 0 0 20px;
						padding-left: 30px;
					}

					.mat-expansion-panel-header {
						.mat-content {
							.mat-expansion-panel-header-title {
								padding-bottom: 10px;
							}
						}
					}

				}

				.mat-expansion-panel-content {
					.mat-expansion-panel-body {
						.mat-form-field {
							.mat-input-element {
								min-height: 40px;
								padding: 0 10px;
								border: 1px solid var(--SecondaryBorder-);
								line-height: 40px;
								border-radius: 3px;
								font-size: 14px;
								color: var(--primaryColor-);
								font-weight: 600;

								&:placeholder {
									font-size: 14px;
									color: var(--inputcolor-);
									letter-spacing: 0;
								}

							}

							.mat-form-field-flex {
								margin-bottom: 10px;
							}
						}

						button {
							width: 100%;
							padding: 0;
							min-height: 40px;
							border: 0;
							box-shadow: 0 1px 5px 1px rgba(108, 148, 184, 0.14), 0 2px 5px 2px rgba(108, 148, 184, 0.12);
							border-radius: 5px;
							font-size: 14px;
						}

					}
				}
			}
		}
	}
}

// Coupon Code
.input-area {
	margin-top: 30px;
	margin-bottom: 0px;

	.mat-form-field {
		.mat-form-field-infix {
			.mat-input-element {
				border: 1px solid var(--SecondaryBorder-);
				min-height: 40px;
				padding: 0 10px;
				border-radius: 5px;

				&::placeholder {
					font-size: 18px;
					color: var(--inputcolor-);
					letter-spacing: 0;
				}

				&:focus {
					border-color: var(--SecondaryBorder-);
				}
			}
		}
	}
}

.uploadDocument {
	.mat-form-field {
		.mat-form-field-flex {
			margin-bottom: 0;
		}

		.mat-form-field-infix {
			&:after {
				right: 24px !important;
				content: "\e90c" !important;
				font-family: 'icomoon' !important;
				margin: 0;
				border: 0 !important;
				top: 50% !important;
				transform: translateY(-50%);
				position: absolute;
			}

			.mat-input-element {
				border: 1px solid var(--inputcolor-) !important;
				padding: 10px;
				border-radius: 5px;
				margin-top: 15px;
				font-size: 14px;
			}
		}

		.mat-form-field-underline {
			display: none;
		}
	}
}

.pricing {
	.view-more {
		.mat-expansion-panel-spacing {
			.mat-expansion-panel-header {
				width: 100%;
				color: var(--linkColor-);
				display: block;
				text-align: center;
				line-height: 20px;
				border-top: solid 1px var(--borderColor-);
				padding: 10px 0;
				margin-top: 10px;

				&.mat-expanded {
					margin: 0;
				}
			}

			.mat-expansion-panel-content {
				padding-top: 0;
				padding-bottom: 10px;

				.mat-expansion-panel-body {
					ul {
						&:first-child {
							li {
								.prc--dates {
									margin: 0;
								}
							}
						}
					}

				}
			}

		}

		.mat-expansion-panel {
			display: flex;
			width: 100%;
			flex-direction: column-reverse;
		}
	}

	.prc--billing {
		.mat-accordion {
			width: 100%;
			display: block;

			.mat-expansion-panel-header {
				width: 100%;

				.mat-content {
					width: 100%;

					.mat-expansion-panel-header-title {
						color: unset;
					}
				}

				.mat-expansion-indicator {
					&::after {
						margin-top: -4px;
					}
				}
			}

			.mat-expansion-panel-spacing {
				.mat-expansion-panel-content {
					padding: 0;
				}
			}

		}
	}
}

.mat-datepicker-popup {
	top: 15% !important;
	left: 50% !important;
	transform: translate(-50%);
}


.login_with_otp {
	.otp-input-box {
		.mat-form-field-infix {
			text-align: center;
			max-width: 186px;
			margin: 0 auto;
			position: relative;

			.mat-form-field-label-wrapper {
				position: absolute !important;
				bottom: 0 !important;
				-webkit-transition: all ease-in-out .3s;
				-moz-transition: all ease-in-out .3s;
				-ms-transition: all ease-in-out .3s;
				-o-transition: all ease-in-out .3s;
				transition: all ease-in-out .3s;

				mat-label {
					font-size: 16px;
				}
			}

			.mat-input-element {
				&::placeholder {
					font-size: 0;
				}
			}
		}

		&.mat-focused {
			.mat-form-field-label-wrapper {
				bottom: 15px !important;
			}

			.mat-form-field-ripple {
				background-color: var(--linkColor-);
			}

		}
	}
}


#price-alert-inp {
	width: 100%;

	.mat-form-field-wrapper {
		padding-bottom: 0;
	}

	.mat-form-field-underline {
		display: none;
	}
}

.mat-form-field-infix {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 0;

	.mat-form-field-label-wrapper,
	.mat-form-field-label {
		position: relative;
		line-height: 1;

		mat-label {
			display: inline-block;
		}

		.mat-placeholder-required {
			display: inline-block;
			margin-left: 3px;
			color: var(--errorColor-);
		}
	}

	.error-field {
		border-bottom-color: var(--errorColor-);
	}

	.error-text {
		position: absolute;
		bottom: -15px;
		color: var(--error-bg);
		font-size: 10px;
	}

	.mat-input-element::placeholder {
		font-size: 18px;
		color: var(--inputcolor-);
		font-weight: 400;
	}
}

.mat-form-field {
	&.otp-input-box {
		.mat-form-field-infix {
			text-align: center;
			justify-content: center;
			border: 0;

			.mat-input-element {
				margin: 0 10px;
				border-bottom: 2px solid var(--SecondaryBorder-);
				padding: 0;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				text-align: center;
				color: var(--primaryColor-);
			}
		}

		.mat-form-field-underline {
			display: none;
		}

		.mat-error {
			text-align: center;
		}

		// &.incorrect-otp {
		// 	// input {
		// 	// 	// border-bottom-color: var(--errorColor-) !important;
		// 	// }
		// }
	}
}


mat-tab-group {
	width: 100%;

	.mat-tab-body-wrapper {
		margin-top: 39px;
		z-index: 1;
	}

	// .mat-tab-header {
	// 	position: fixed;
	// 	width: 100%;
	// 	z-index: 100;
	// }
}



.verification-form {
	.mat-form-field-label-wrapper {
		margin: 0;
		overflow: visible;
		padding: 0;

		mat-label {
			margin: 0;
		}
	}
}




.mat-select-panel {
	max-width: 280px !important;
	min-width: 100% !important;
	margin: 0 auto !important
}

.cdk-global-overlay-wrapper {
	.cdk-overlay-pane {
		.mat-dialog-container {
			padding: 0;

			.cdk-global-overlay-wrapper {
				width: 100%;
				display: block;
			}
		}

		&.mat-datepicker-popup {
			height: auto;
		}

	}

	.cdk-global-overlay-wrapper {
		display: block;

		.cdk-overlay-pane {
			max-width: 100% !important;
			height: 100%;

			.mat-dialog-container {
				padding: 0;

				.cdk-global-overlay-wrapper {
					width: 100%;
					display: block;
				}
			}

			&.mat-datepicker-popup {
				height: auto;
			}

		}
	}


	.camera-action {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.actionBtn {
			width: 50%;
			text-align: center;
		}

		.choose-file {
			width: 100%;
			margin: 0 50%;
			margin-top: 40px;

			label {
				font-size: 18px;
				margin-bottom: 7px;
				display: block;
				font-weight: 500;
			}
		}

		.actionBtn {
			width: 50%;
			text-align: center;
		}

		.choose-file {
			width: 100%;
			margin: 0 50%;
			margin-top: 40px;

			label {
				font-size: 18px;
				margin-bottom: 7px;
				display: block;
				font-weight: 500;
			}
		}

	}

	.verification-form {
		.mat-form-field-label-wrapper {
			margin: 0;
			overflow: visible;
			padding: 0;

			mat-label {
				margin: 0;
			}
		}
	}

	.mat-select-panel {
		max-width: 280px !important;
		min-width: 100% !important;
		margin: 0 auto !important
	}
}

// Input Focused Underline Styling

.mat-form-field.mat-focused span.mat-form-field-ripple {
	background-color: var(--linkColor-)
}

// Calndar Popup Style

.mat-calendar-body-selected {
	background-color: var(--basePrimary-);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	border-color: var(--basePrimary-);
	color: var(--basePrimary-);
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
	padding: 0;
}

.faq-accordion {
	.mat-accordion {
		.mat-expansion-panel {
			border: 1px solid var(--borderColor-);
			border-radius: 5px;
			padding: 15px;

			&-header {
				display: flex;
				justify-content: space-between;
				height: auto;

				.mat-expansion-indicator {
					top: 50%;
					transform: translateY(-50%);

					&:after {
						content: "+";
						transform: none;
						border: 0;
						font-size: 30px;
						color: var(--primaryColor-);
					}
				}

				&-title {
					margin-bottom: 0;
					font-size: 12px;
					color: var(--primaryColor-);
				}

				&.mat-expanded {
					.mat-expansion-indicator {
						&:after {
							content: "-";
							transform: none;
							border: 0;
							font-size: 30px;
							color: var(--primaryColor-);
						}
					}
				}
			}

			&.mat-expanded {
				.mat-expansion-panel-content {
					padding-bottom: 0;
					padding-top: 10px;

					.mat-expansion-panel-body {
						font-size: 12px;
					}
				}
			}

		}
	}
}

.fund--info {
	.mat-radio-group {
		display: flex;
		justify-content: end;
		margin: 0;

		.mat-radio-checked {
			.mat-radio-label {
				&-content {
					background-color: var(--basePrimary-);
					color: var(--whiteColor-);
					border-color: var(--basePrimary-);
					padding: 6px 14px;
					margin-right: 0;
				}
			}
		}

		.mat-radio-button {
			&:nth-child(2) {
				.mat-radio-label-content {
					margin: 0;
				}
			}

		}
	}
}

.fund--box {
	.mat-form-field-wrapper {
		padding-bottom: 0;

		.mat-form-field-prefix {
			font-size: 36px;
		}

		.mat-form-field-underline {
			display: none;
		}
	}

	.mat-radio-group {
		display: flex;
		justify-content: start;

		.mat-radio-label-content {
			width: 60px;
			text-align: center;
			height: 25px;
			line-height: 25px;
			padding: 0;
			border-color: var(--linkColor-);
			color: var(--linkColor-);
		}
	}

}

.fund--payment {
	.mat-accordion {
		.mat-expansion-panel {
			border-bottom: 1px solid var(--inputcolor-) !important;
			border-radius: 0 !important;

			.mat-expansion-panel-header {
				width: 100%;
				height: auto;
			}

			&-spacing {
				.mat-expansion-panel-content {
					padding: 0;
					padding-bottom: 15px;

					.upi--input {
						.mat-form-field {
							.mat-form-field-wrapper {
								padding: 0;

								.mat-form-field-infix {
									padding: 0;
									border: solid 1px var(--borderColor-);
									border-radius: 4px;
									margin: 0;
								}

								.mat-form-field-subscript-wrapper {
									top: 100%;
									padding: 0;
									margin-top: 2px;
								}
							}

							&-underline {
								display: none;
							}

						}
					}

				}
			}
		}

		.mat-expansion-indicator {
			display: none;
		}

		.mat-content {
			width: 100%;

			.mat-expansion-panel-header-title {
				width: 100%;
				margin: 0;
			}
		}
	}
}



// Bank Account Dropdown

// .cdk-global-overlay-wrapper {
// 	height: 100%;
// 	background-color: rgb(0 0 0 / 50%);

// 	.mat-snack-bar-handset {
// 		position: fixed !important;
// 		bottom: 0;
// 		max-height: 210px;

// 		.mat-snack-bar-container {
// 			border-radius: 20px 20px 0 0;
// 			padding: 20px 15px;
// 			text-align: center;

// 			// background-color: var(--whiteColor-);
// 			.header {
// 				color: var(--primaryColor-);
// 				font-size: 18px;
// 				font-weight: 500;
// 			}

// 			.bank-name {
// 				margin-top: 20px;

// 				li {
// 					display: flex;
// 					justify-content: space-between;
// 					font-weight: 500;
// 					color: var(--primaryColor-);
// 					font-size: 16px;
// 					border-bottom: 1px solid var(--SecondaryBorder-);
// 					padding: 15px 0;

// 					&:last-child {
// 						border: 0;
// 					}

// 					.icon-check {
// 						color: var(--linkColor-);
// 						font-size: 20px;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// Ledger

.ledger {
	.mat-tab-header {
		.mat-tab-label-container {
			.mat-tab-labels {
				justify-content: center;
				column-gap: 40px;
			}
		}
	}
}

.filter-option {
	.mat-radio-group {
		display: flex;
		flex-wrap: wrap;
		column-gap: 10px;
		row-gap: 10px;

		&.date-range {
			column-gap: 0;

			.mat-radio-group {
				column-gap: 0;
				margin: 0;
			}
		}

		mat-label {
			width: 100%;

			&.range-label {
				font-size: 11px;
				color: #A1A4B9;
				letter-spacing: 0;
				margin: 0;
			}
		}

		.mat-radio-button {
			margin: 0;
		}

		.mat-radio-label-content {
			margin: 0;
			padding: 5px 10px;
		}
	}

}

.list--menu {
	.mat-accordion {
		.mat-expansion-panel {
			&-spacing {
				&-content {
					padding: 0;
				}
			}

			&-header {
				width: 100%;
				height: auto;
				position: relative;

				&-title {
					width: 100%;
				}
			}
		}

		.mat-content {
			width: 100%;
			height: auto;

			.mat-expansion-panel-header-title {
				margin: 0;
				color: var(--primaryColor-) !important;
			}
		}

		.mat-expansion-indicator {
			position: absolute;
			top: 43%;
			transform: rotate(-90deg);
			right: 10px;

			&:after {
				color: var(--tertiaryColor-);
				border-color: var(--tertiaryColor-);
			}
		}

		&.mat-expanded {
			span.mat-expansion-indicator {
				transform: rotate(180deg) !important;
			}
		}
	}
}



@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Add animation to "page content" */
.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s
}

@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}

	to {
		bottom: 0px;
		opacity: 1
	}
}

@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}

	to {
		bottom: 0;
		opacity: 1
	}
}



.ledger {
	.mat-radio-group {
		display: flex;
		justify-content: center;
		margin: 0;
		margin-bottom: 20px;

		.mat-radio-label-content {
			padding: 6px 14px;
		}

		.mat-radio-checked {
			.mat-radio-label {
				&-content {
					background-color: var(--basePrimary-);
					color: var(--whiteColor-);
					border-color: var(--basePrimary-);
				}
			}
		}

		.mat-radio-button {
			&:nth-child(2) {
				.mat-radio-label-content {
					margin: 0;
				}
			}

		}
	}
}



// Add Fund Input


.input-val {
	padding: 20px 15px;
	text-align: center;

	.mat-form-field {
		text-align: center;

		.mat-form-field-underline {
			width: 200px;
			left: 50%;
			margin: 0 auto;
			transform: translateX(-50%);
		}

		mat-label {
			font-size: 22px;
			margin-bottom: 0px;
			color: var(--labelcolor-);
		}

		.mat-input-element {
			&::placeholder {
				font-size: 24px;
			}
		}
	}
}

.mat-slider-wrapper .mat-slider-thumb {
	width: 8px;
	height: 8px;
	border: 0;
	bottom: -4px;
	right: -5px;
}

.mat-slider.cdk-focused.mat-slider-horizontal .mat-slider-thumb-container .mat-slider-thumb:after {
	content: "";
	width: 4px;
	height: 4px;
	position: absolute;
	border: 12px solid rgb(0 128 255 / 29%);
	top: -8px;
	border-radius: 100%;
	right: -9px;
}

.input-val .mat-form-field-infix .mat-input-element {
	height: 40px;
	padding: 0;
}

#price-alert-sel {
	.mat-form-field-wrapper {
		padding-bottom: 0;
	}

	.mat-form-field-underline {
		display: none;
	}

	.mat-form-field-infix {
		flex-wrap: inherit;
		display: flow-root;
		border: none;

		.mat-input-element {
			font-size: 14px;
		}

		.mat-form-field-label-wrapper {
			position: absolute;
		}
	}

	mat-select {
		.mat-select-arrow-wrapper {
			transform: inherit;
		}
	}
}

// --------------------css for desktop version----------------------------
.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.no_margin {
	margin: 0px;
}

.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;

	@media @screen-mobile {
		width: 100%;
	}
}

.p-0 {
	padding: 0;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	float: left;
}

@media (min-width:768px) {

	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9 {
		float: left;
	}

	.col-sm-12 {
		width: 100%;
	}

	.col-sm-11 {
		width: 91.66666667%;
	}

	.col-sm-10 {
		width: 83.33333333%;
	}

	.col-sm-9 {
		width: 75%;
	}

	.col-sm-8 {
		width: 66.66666667%;
	}

	.col-sm-7 {
		width: 58.33333333%;
	}

	.col-sm-6 {
		width: 50%;
	}

	.col-sm-5 {
		width: 41.66666667%;
	}

	.col-sm-4 {
		width: 33.33333333%;
	}

	.col-sm-3 {
		width: 25%;
	}

	.col-sm-2 {
		width: 16.66666667%;
	}

	.col-sm-1 {
		width: 8.33333333%;
	}

	.col-sm-pull-12 {
		right: 100%;
	}

	.col-sm-pull-11 {
		right: 91.66666667%;
	}

	.col-sm-pull-10 {
		right: 83.33333333%;
	}

	.col-sm-pull-9 {
		right: 75%;
	}

	.col-sm-pull-8 {
		right: 66.66666667%;
		z-index: 2;
	}

	.col-sm-pull-7 {
		right: 58.33333333%;
	}

	.col-sm-pull-6 {
		right: 50%;
	}

	.col-sm-pull-5 {
		right: 41.66666667%;
	}

	.col-sm-pull-4 {
		right: 33.33333333%;
	}

	.col-sm-pull-3 {
		right: 25%;
	}

	.col-sm-pull-2 {
		right: 16.66666667%;
	}

	.col-sm-pull-1 {
		right: 8.33333333%;
	}

	.col-sm-pull-0 {
		right: auto;
	}

	.col-sm-push-12 {
		left: 100%;
	}

	.col-sm-push-11 {
		left: 91.66666667%;
	}

	.col-sm-push-10 {
		left: 83.33333333%;
	}

	.col-sm-push-9 {
		left: 75%;
	}

	.col-sm-push-8 {
		left: 66.66666667%;
	}

	.col-sm-push-7 {
		left: 58.33333333%;
	}

	.col-sm-push-6 {
		left: 50%;
	}

	.col-sm-push-5 {
		left: 41.66666667%;
	}

	.col-sm-push-4 {
		left: 33.33333333%;
	}

	.col-sm-push-3 {
		left: 25%;
	}

	.col-sm-push-2 {
		left: 16.66666667%;
	}

	.col-sm-push-1 {
		left: 8.33333333%;
	}

	.col-sm-push-0 {
		left: auto;
	}

	.col-sm-offset-12 {
		margin-left: 100%;
	}

	.col-sm-offset-11 {
		margin-left: 91.66666667%;
	}

	.col-sm-offset-10 {
		margin-left: 83.33333333%;
	}

	.col-sm-offset-9 {
		margin-left: 75%;
	}

	.col-sm-offset-8 {
		margin-left: 66.66666667%;
	}

	.col-sm-offset-7 {
		margin-left: 58.33333333%;
	}

	.col-sm-offset-6 {
		margin-left: 50%;
	}

	.col-sm-offset-5 {
		margin-left: 41.66666667%;
	}

	.col-sm-offset-4 {
		margin-left: 33.33333333%;
	}

	.col-sm-offset-3 {
		margin-left: 25%;
	}

	.col-sm-offset-2 {
		margin-left: 16.66666667%;
	}

	.col-sm-offset-1 {
		margin-left: 8.33333333%;
	}

	.pt-3 {
		padding-top: 30px;
	}

	.col-sm-offset-0 {
		margin-left: 0;
	}

	.bottom-button-wrapper {
		justify-content: center;
		box-shadow: none;

		button {
			max-width: 250px;
			cursor: pointer;
		}

		&.loader-btn {
			display: flex;
			align-items: center;
			justify-content: center;
		}

	}
}



@media (max-width:767px) {

	.col-xs-1,
	.col-xs-10,
	.col-xs-11,
	.col-xs-12,
	.col-xs-2,
	.col-xs-3,
	.col-xs-4,
	.col-xs-5,
	.col-xs-6,
	.col-xs-7,
	.col-xs-8,
	.col-xs-9 {
		float: none;
		display: inline-block;
	}

	.col-xs-12 {
		width: 100% !important;
	}

	.col-xs-11 {
		width: 91.66666667%;
	}

	.col-xs-10 {
		width: 83.33333333%;
	}

	.col-xs-9 {
		width: 75%;
	}

	.col-xs-8 {
		width: 66.66666667%;
	}

	.col-xs-7 {
		width: 58.33333333%;
	}

	.col-xs-6 {
		width: 50%;
	}

	.col-xs-5 {
		width: 41.66666667%;
	}

	.col-xs-4 {
		width: 33.33333333%;
	}

	.col-xs-3 {
		width: 25%;
	}

	.col-xs-2 {
		width: 16.66666667%;
	}

	.col-xs-1 {
		width: 8.33333333%;
	}

	.col-xs-pull-12 {
		right: 100%;
	}

	.col-xs-pull-11 {
		right: 91.66666667%;
	}

	.col-xs-pull-10 {
		right: 83.33333333%;
	}

	.col-xs-pull-9 {
		right: 75%;
	}

	.col-xs-pull-8 {
		right: 66.66666667%;
		z-index: 2;
	}

	.col-xs-pull-7 {
		right: 58.33333333%;
	}

	.col-xs-pull-6 {
		right: 50%;
	}

	.col-xs-pull-5 {
		right: 41.66666667%;
	}

	.col-xs-pull-4 {
		right: 33.33333333%;
	}

	.col-xs-pull-3 {
		right: 25%;
	}

	.col-xs-pull-2 {
		right: 16.66666667%;
	}

	.col-xs-pull-1 {
		right: 8.33333333%;
	}

	.col-xs-pull-0 {
		right: auto;
	}

	.col-xs-push-12 {
		left: 100%;
	}

	.col-xs-push-11 {
		left: 91.66666667%;
	}

	.col-xs-push-10 {
		left: 83.33333333%;
	}

	.col-xs-push-9 {
		left: 75%;
	}

	.col-xs-push-8 {
		left: 66.66666667%;
	}

	.col-xs-push-7 {
		left: 58.33333333%;
	}

	.col-xs-push-6 {
		left: 50%;
	}

	.col-xs-push-5 {
		left: 41.66666667%;
	}

	.col-xs-push-4 {
		left: 33.33333333%;
	}

	.col-xs-push-3 {
		left: 25%;
	}

	.col-xs-push-2 {
		left: 16.66666667%;
	}

	.col-xs-push-1 {
		left: 8.33333333%;
	}

	.col-xs-push-0 {
		left: auto;
	}

	.col-xs-offset-12 {
		margin-left: 100%;
	}

	.col-xs-offset-11 {
		margin-left: 91.66666667%;
	}

	.col-xs-offset-10 {
		margin-left: 83.33333333%;
	}

	.col-xs-offset-9 {
		margin-left: 75%;
	}

	.col-xs-offset-8 {
		margin-left: 66.66666667%;
	}

	.col-xs-offset-7 {
		margin-left: 58.33333333%;
	}

	.col-xs-offset-6 {
		margin-left: 50%;
	}

	.col-xs-offset-5 {
		margin-left: 41.66666667%;
	}

	.col-xs-offset-4 {
		margin-left: 33.33333333%;
	}

	.col-xs-offset-3 {
		margin-left: 25%;
	}

	.col-xs-offset-2 {
		margin-left: 16.66666667%;
	}

	.col-xs-offset-1 {
		margin-left: 8.33333333%;
	}
}


@screen-small-mobile: ~"only screen and (max-width:360px)";
@screen-mobile: ~"only screen and (max-width:767px)";
@screen-tabSmall: ~"only screen and (min-device-width:768px) and (max-device-width:1023px) and (-webkit-min-device-pixel-ratio: 2)";
@screen-tab: ~"only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)";
@screen-desktop: ~"only screen and (max-width:1280px)";
@screen-laptop: ~"only screen and (max-width:1400px)";
@screen-bigscreen: ~"only screen and (min-width:768px)";

.interesing-facts {
	padding: 30px 10px 10px 10px;
	background: #153A5B;
	color: var(--whiteColor-);
	overflow-y: auto;
	height: 75vh;

	h2,
	h3 {
		margin-bottom: 15px;
		font-size: 18px;
	}

	p {
		line-height: 1.5;
		font-size: 14px;
		margin-bottom: 15px;
	}

	@media @screen-mobile {
		height: auto;
		overflow: hidden;
	}

	&::-webkit-scrollbar {
		width: 10px
	}

	&::-webkit-scrollbar-track {
		background: #153A5B;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #1d4467;
		border-radius: 20px;
	}

	@media @screen-mobile {
		padding: 0;
		margin: 15px;
		border-radius: 10px;

		>div {
			padding: 30px 15px 25px 15px;
		}
	}

	&__ul {
		padding-bottom: 30px;
		padding-left: 15px;

		li {
			list-style: disc;
			margin-bottom: 10px;
		}
	}

	&__quest {
		font-weight: 400;
	}

	&__ans {
		font-weight: 200;
		opacity: 0.6;
	}

	&__info {
		display: inline-block;
		background: #628AD3;
		padding: 5px 40px 5px 50px;
		position: relative;
		font-size: 16px;
		margin-bottom: 20px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 20px;
		margin-left: 10px;

		span {
			background: #628AD3;
			position: absolute;
			padding: 10px;
			border-radius: 50%;
			display: inline-block;
			width: 40px;
			height: 40px;
			text-align: center;
			left: -9px;
			top: -7px;
			box-shadow: 2px 1px 0 var(--whiteColor-);
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			right: -14px;
			top: 0;
			border-top-right-radius: 5px;
			width: 20px;
			height: 40px;
			background: #153a5b;
			transform: rotate(27deg);
		}
	}

	&__doubt {
		display: inline-block;
		background: #DF897E;
		padding: 5px 40px 5px 50px;
		position: relative;
		font-size: 16px;
		margin-bottom: 20px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 20px;
		margin-left: 10px;

		span {
			background: #DF897E;
			position: absolute;
			padding: 10px;
			border-radius: 50%;
			display: inline-block;
			width: 40px;
			height: 40px;
			text-align: center;
			left: -9px;
			top: -7px;
			box-shadow: 2px 1px 0 var(--whiteColor-);
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			right: -14px;
			top: 0;
			border-top-right-radius: 5px;
			width: 20px;
			height: 40px;
			background: #153a5b;
			transform: rotate(27deg);
		}
	}
}

._header_progress {
	max-width: 350px;
}

.main-section {
	margin-top: 75px;

	@media @screen-mobile {
		margin-top: 20px;
	}

	.contain--box {
		position: relative;
		padding-left: 5%;
		height: 78vh;

		@media @screen-mobile {
			height: auto;
			padding: 0;
		}

		&:after {
			content: "";
			width: 2500px;
			max-width: 37%;
			background: #153A5B;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;

			@media @screen-mobile {
				display: none;
			}
		}

		.auto--scroll {
			overflow-y: auto;
			overflow-x: hidden;
			// height: 78vh;
			height: 512.5px;
			padding: 0 25px 15px;
			max-width: 800px;

			@media @screen-mobile {
				height: auto;
				padding: 0 5px 25px;
				overflow-x: hidden;
			}

			&::-webkit-scrollbar {
				width: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #ececec;
				border-radius: 20px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #cecece;
				border-radius: 20px;
			}
		}
	}
}

// --------------------css for desktop version----------------------------
.cdk-overlay-container {
	.mat-snack-bar-container {
		max-width: 100vw;
		min-width: 100vw;
		min-height: 100vh;
		margin: 0px;

		.toast_message {
			@media @screen-bigscreen {
				max-width: 400px;
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			@media @screen-mobile {
				position: fixed;
				width: 95%;
				margin: 0 auto;
				left: 50%;
				bottom: 35px;
				transform: translateX(-50%);
			}
		}
	}
}

::hidden {
	display: none;
}



.mat-radio-button {
	&.mat-accent {
		.mat-radio-inner-circle {
			background-color: var(--linkColor-);
		}

		&.mat-radio-checked {
			.mat-radio-outer-circle {
				border-color: var(--linkColor-);
			}
		}
	}
}

.mat-dialog-container {
	padding: 0;

	.cdk-global-overlay-wrapper {
		div.cdk-overlay-pane {
			width: 100%;
			max-width: 100%;
		}
	}
}




.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	top: -5px;
	border-radius: 5px;
	background-color: var(--whiteColor-);
	color: var(--whiteColor-);
	box-shadow: 9999px 0 0 -5px var(--whiteColor-);
	animation: dotPulse 1.5s infinite linear;
	animation-delay: .25s;
}

.btn--border-dot-pulse {
	background-color: var(--basePrimary-);
	color: var(--basePrimary-);
	box-shadow: 9999px 0 0 -5px var(--basePrimary-);
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	animation: dotPulse 1.5s infinite linear;
	animation-delay: .25s;
}

.btn--border-dot-pulse::before,
.btn--border-dot-pulse::after {
	content: '';
	background-color: var(--basePrimary-);
	color: var(--basePrimary-);
}

.btn--border-dot-pulse::before {
	box-shadow: 9984px 0 0 -5px var(--basePrimary-);
	animation: BorderdotPulseBefore 1.5s infinite linear;
	animation-delay: 0s;
}

.btn--border-dot-pulse::after {
	box-shadow: 10014px 0 0 -5px var(--basePrimary-);
	animation: BorderdotPulseAfter 1.5s infinite linear;
	animation-delay: .5s;
}

@keyframes BorderdotPulseBefore {
	0% {
		box-shadow: 9984px 0 0 -5px var(--basePrimary-);
	}

	30% {
		box-shadow: 9984px 0 0 2px var(--basePrimary-);
	}

	60%,
	100% {
		box-shadow: 9984px 0 0 -5px var(--basePrimary-);
	}
}

@keyframes BorderdotPulse {
	0% {
		box-shadow: 9999px 0 0 -5px var(--basePrimary-);
	}

	30% {
		box-shadow: 9999px 0 0 2px var(--basePrimary-);
	}

	60%,
	100% {
		box-shadow: 9999px 0 0 -5px var(--basePrimary-);
	}
}

@keyframes BorderdotPulseAfter {
	0% {
		box-shadow: 10014px 0 0 -5px var(--basePrimary-);
	}

	30% {
		box-shadow: 10014px 0 0 2px var(--basePrimary-);
	}

	60%,
	100% {
		box-shadow: 10014px 0 0 -5px var(--basePrimary-);
	}
}


.dot-pulse::before,
.dot-pulse::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--whiteColor-);
	color: var(--whiteColor-);
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px var(--whiteColor-);
	animation: dotPulseBefore 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px var(--whiteColor-);
	animation: dotPulseAfter 1.5s infinite linear;
	animation-delay: .5s;
}

@keyframes dotPulseBefore {
	0% {
		box-shadow: 9984px 0 0 -5px var(--whiteColor-);
	}

	30% {
		box-shadow: 9984px 0 0 2px var(--whiteColor-);
	}

	60%,
	100% {
		box-shadow: 9984px 0 0 -5px var(--whiteColor-);
	}
}

@keyframes dotPulse {
	0% {
		box-shadow: 9999px 0 0 -5px var(--whiteColor-);
	}

	30% {
		box-shadow: 9999px 0 0 2px var(--whiteColor-);
	}

	60%,
	100% {
		box-shadow: 9999px 0 0 -5px var(--whiteColor-);
	}
}

@keyframes dotPulseAfter {
	0% {
		box-shadow: 10014px 0 0 -5px var(--whiteColor-);
	}

	30% {
		box-shadow: 10014px 0 0 2px var(--whiteColor-);
	}

	60%,
	100% {
		box-shadow: 10014px 0 0 -5px var(--whiteColor-);
	}
}

/* Material Input Type Text  Start*/
.mat-form-field {
	width: 100%;

	.mat-form-field-flex {
		padding: 0 !important;
		background-color: transparent;
	}

	.mat-form-field-infix {

		.mat-form-field-label-wrapper,
		.mat-form-field-label {
			mat-label {
				display: inline-block;
				margin: 0;
			}
		}
	}
}


//bottomSheet Setup
.coupon-container {
	/* padding: 8px 16px; */
	min-width: 100vw !important;
	box-sizing: border-box;
	display: block;
	outline: 0;
	max-height: 40vh !important;
	overflow: auto;
}

//market value for success and failure
.market--val {
	color: var(--successColor-);
	position: relative;
	font-weight: 500;

	&.val--negative {
		color: var(--errorColor-);
	}

	&.val--neutral {
		color: var(--black-bg-);
	}
}

.market--val-bg {
	font-weight: 500;
	padding: 3px;

	&.posi--bg {
		background-color: var(--success-bg);
	}

	&.nega--bg {
		background-color: var(--error-bg);
		color: var(--whiteColor-);
	}

	&.neutral--bg {
		background-color: var(--whiteColor-);
	}
}

//market value for success and failure
//set dark-mode, light mode classes for future purposes

#zohohc-asap-web-launcherbox {
	display: none !important;
}

#zohohc-asap-web-layoutbox {
	width: 100%;
}




.loading--shimmer {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f9;
	background-image: linear-gradient(to right,
			#f6f7f9 0%,
			#e9ebee 20%,
			#f6f7f9 40%,
			#f6f7f9 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	height: 8px;
	position: relative;
	display: inline-block !important;

	&.w10 {
		width: 10%;
	}

	&.w20 {
		width: 20%;
	}

	&.w30 {
		width: 30%;
	}

	&.w40 {
		width: 40%;
	}

	&.w50 {
		width: 50%;
	}

	&.w60 {
		width: 60%;
	}

	&.w70 {
		width: 70%;
	}

	&.w80 {
		width: 80%;
	}

	&.w90 {
		width: 90%;
	}

	&.w100 {
		width: 100%;
	}
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}

	100% {
		background-position: 468px 0;
	}
}

.shimmer--control {
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 50%;
		position: absolute;
		bottom: 0;
		background-color: transparent;
		background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.7));
		z-index: 1;
	}
}

.button {
	.button--loader {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background: var(--primaryColor-);
		pointer-events: none;
		cursor: none;
		z-index: 1;

		span {
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: var(--whiteColor-);
			opacity: .2;
			animation-name: buttonLoad;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			transform: scale(.3);
			animation-delay: 0s;
			margin: 12px 5px;

			&:nth-child(2) {
				animation-delay: .1s;
			}

			&:nth-child(3) {
				animation-delay: .2s;
			}

			@keyframes buttonLoad {
				0% {
					opacity: .2;
				}

				20% {
					opacity: 1;
					transform: scale(1);
				}

				100% {
					opacity: .2;
					transform: scale(.3);
				}
			}
		}
	}
}


// Input Global Styling 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}



// IPO 



.ipo-bg {
	.ipo {
		.mat-tab-group {
			.mat-tab-body-wrapper {
				margin-top: 0; //spacing issue
			}
		}
	}
}

// StockSIp Know More Popup 

.knowmore-popup {
	border-top-left-radius: 30px !important;
	border-top-right-radius: 30px !important;
}

.SPOT {
    .toaster--messager {
        &.negative--toaster {
            .icon-Close {
                border-radius: 50%;
                background: var(--whiteColor-);
                color: var(--errorColor-);
                width: 22px;
                height: 22px;
                line-height: 1;
                padding: 3px;
                text-align: center;
                font-size: 17px;
            }
        }

        &.toaster--open {
            bottom: 20px;
            padding-left: 10px;
        }

        .text-message {
            margin-left: 5px;
        }
    }
}

/deep/ .nfo--main {
	.mat-tab-group {
		.mat-tab-header {
			.mat-tab-label-container {
				.mat-tab-list {
					.mat-tab-labels {
						justify-content: space-between !important;
					}
				}
			}
		}
	}
}

.activation-bottomsheet {
    border-radius: 12px 12px 0 0;
    .account-opening-popup {
        padding: 15px 0;
        h3 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        .content {
            ul {
                margin-bottom: 15px;
                li {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 5px;
                }

            }
			button {
				width: 100%;
				padding: 13px;
				font-size: 14px;
				border: 0;
				border-radius: 4px;
				background-color: #e31b54;
			}
        }
    }
}
